var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-4",attrs:{"outlined":"","elevation":"6"}},[_c('v-dialog',{attrs:{"max-width":"50vw"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('img',{attrs:{"src":"/img/cheque.png","alt":"","width":"100%"},on:{"click":function($event){$event.stopPropagation();_vm.dialog=false}}})]),_c('div',{staticClass:"d-flex justify-center pb-4"},[_c('v-card-title',{staticClass:"text-center secondary--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('caTerm.banking.title'))+" ")])],1),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('validation-provider',{attrs:{"rules":{required:true,validi18nName:true}},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('caTerm.banking.accountHolder'),"outlined":""},model:{value:(_vm.accountHolder),callback:function ($$v) {_vm.accountHolder=$$v},expression:"accountHolder"}})]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('v-select',{attrs:{"items":_vm.accountTypes,"error-messages":errors,"label":_vm.$t('caTerm.banking.accountType'),"outlined":""},model:{value:(_vm.accountType),callback:function ($$v) {_vm.accountType=$$v},expression:"accountType"}})]}}])}),_c('validation-provider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('v-text-field',{attrs:{"append-icon":"mdi-information-outline","error-messages":errors,"label":_vm.$t('caTerm.banking.transitNumber'),"outlined":""},on:{"click:append":()=>{
          _vm.dialog=true }},model:{value:(_vm.transitNumber),callback:function ($$v) {_vm.transitNumber=$$v},expression:"transitNumber"}})]}}])}),_c('validation-provider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('v-text-field',{attrs:{"append-icon":"mdi-information-outline","error-messages":errors,"label":_vm.$t('caTerm.banking.institutionNumber'),"outlined":""},on:{"click:append":()=>{
          _vm.dialog=true }},model:{value:(_vm.institutionNumber),callback:function ($$v) {_vm.institutionNumber=$$v},expression:"institutionNumber"}})]}}])}),_c('validation-provider',{attrs:{"rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('v-text-field',{attrs:{"append-icon":"mdi-information-outline","error-messages":errors,"label":_vm.$t('caTerm.banking.accountNumber'),"outlined":""},on:{"click:append":()=>{
          _vm.dialog=true }},model:{value:(_vm.accountNumber),callback:function ($$v) {_vm.accountNumber=$$v},expression:"accountNumber"}})]}}])}),_c('validation-provider',{attrs:{"rules":{required:true,validi18nName:true}},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('v-text-field',{attrs:{"label":_vm.$t('caTerm.banking.bankName'),"error-messages":errors,"outlined":""},model:{value:(_vm.bankName),callback:function ($$v) {_vm.bankName=$$v},expression:"bankName"}})]}}])}),(_vm.paymentFrequency==='monthly')?_c('p',[_vm._v(" "+_vm._s(_vm.$t('caTerm.banking.monthlyStatement').replace("%%amount%%", _vm.premiumAmount))+" ")]):_vm._e(),(_vm.paymentFrequency==='annual')?_c('p',[_vm._v(" "+_vm._s(_vm.$t('caTerm.banking.annualStatement').replace("%%amount%%", _vm.premiumAmount))+" ")]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.$t('caTerm.banking.policyUse')))]),_c('validation-provider',{staticClass:"radio-below",attrs:{"rules":{requiredOption:true}},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('v-radio-group',{staticClass:"mb-4",attrs:{"error-messages":errors,"row":""},model:{value:(_vm.policyUse),callback:function ($$v) {_vm.policyUse=$$v},expression:"policyUse"}},[_c('v-radio',{attrs:{"color":"blanketbutton","text":"","outlined":"","value":"p","label":_vm.$t('caTerm.banking.personal')},on:{"click":function($event){return _vm.clickedFieldGlobal(
              'p',
              5,
              'policyUse'
            )}}}),_c('v-radio',{attrs:{"color":"blanketbutton","text":"","outlined":"","value":"b","label":_vm.$t('caTerm.banking.business')},on:{"click":function($event){return _vm.clickedFieldGlobal(
              'b',
              5,
              'policyUse'
            )}}})],1)]}}])}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.disclaimer)}}),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('caTerm.banking.dateLabel')))]),_vm._v(" "+_vm._s(_vm.formattedDate(_vm.$i18n.locale))+" ")]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"text-center font-weight-bold",attrs:{"disabled":!_vm.valid,"x-large":"","color":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.$t('common.buttons.confirmAndSubmit'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }