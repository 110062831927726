<template>
  <v-stepper-content step="1">
    <ValidationObserver ref="observer1">
      <v-form
        ref="form1"
        v-model="parent.valid"
        data-testid="preApplicationForm"
        lazy-validation
        @submit.prevent="navigateToAssessment"
      >
        <v-card
          min-height="300"
          elevation="0"
        >
          <div
            class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
          >
            {{ $t('caTerm.preApplication') }}
          </div>
          <v-row class="justify-center">
            <v-col
              cols="11"
              md="8"
            >
              <p>{{ $t("caTerm.basicInfo.fullName") }}</p>
              <div>
                <v-row>
                  <v-col
                    cols="11"
                    sm="4"
                    md="4"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="First Name"
                      :rules="{
                        required: true,
                        validi18nName:true,
                        max: 20,
                      }"
                    >
                      <v-text-field
                        v-model="parent.user.firstName"
                        data-testid="firstname"
                        :label="$t('caTerm.basicInfo.firstName')"
                        color="primary"
                        outlined
                        :error-messages="errors"
                        name="firstName"
                        @click="
                          clickedFieldGlobal(
                            'firstName',
                            1,
                            'CA Term Survey v1'
                          )
                        "
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="11"
                    sm="2"
                    md="2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Middle Initial"
                      rules="alpha"
                    >
                      <v-text-field
                        v-model="parent.user.middleInitial"
                        v-mask="'A'"
                        data-testid="middleInitial"
                        :error-messages="errors"
                        :label="$t('caTerm.basicInfo.middleInitial')"
                        color="primary"
                        outlined
                        name="middleInitial"
                        @click="
                          clickedFieldGlobal(
                            'middleInitial',
                            2,
                            'CA Term Survey v1'
                          )
                        "
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="11"
                    sm="4"
                    md="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Last Name"
                      :rules="{
                        required: true,
                        validi18nName:true,
                      }"
                    >
                      <v-text-field
                        v-model="parent.user.lastName"

                        data-testid="lastname"
                        :label="$t('caTerm.basicInfo.lastName')"
                        color="primary"
                        outlined
                        :error-messages="errors"
                        name="lastName"
                        @click="
                          clickedFieldGlobal(
                            'lastName',
                            3,
                            'CA Term Survey v1'
                          )
                        "
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="11"
                    sm="2"
                    md="2"
                  >
                    <v-select
                      ref="suffix"
                      v-model="parent.user.suffix"
                      :items="parent.SUFFIXES"
                      :label="$t('caTerm.basicInfo.suffix.text')"
                      color="primary"
                      outlined
                      name="suffix"
                      @change="checkSuffix"
                      @click="
                        clickedFieldGlobal(
                          'suffix',
                          4,
                          'CA Term Survey v1'
                        )
                      "
                    />
                  </v-col>
                </v-row>
              </div>
              <p>{{ $t("caTerm.basicInfo.sexAtBirth") }}</p>

              <validation-provider
                v-slot="{ errors }"
                rules="requiredOption"
              >
                <v-radio-group
                  v-model="parent.user.sex"
                  :error-messages="errors"
                  class="mb-4"
                  row
                >
                  <v-radio
                    color="blanketbutton"
                    text
                    outlined
                    value="M"
                    :label="$t('caTerm.basicInfo.male')"
                    name="isMale"
                    @click="
                      clickedFieldGlobal(
                        'sex is male',
                        5,
                        'CA Term Survey v1'
                      )
                    "
                  >
                  </v-radio>

                  <v-radio
                    color="blanketbutton"
                    text
                    outlined
                    value="F"
                    :label="$t('caTerm.basicInfo.female')"
                    name="isFemale"
                    @click="
                      clickedFieldGlobal(
                        'sex is female',
                        5,
                        'CA Term Survey v1'
                      )
                    "
                  >
                  </v-radio>
                </v-radio-group>
              </validation-provider>
              <p>{{ $t("caTerm.basicInfo.dateOfBirthQuestion") }}</p>
              <v-row>
                <v-col
                  cols="11"
                  sm="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Date of birth"
                    :rules="{
                      required: true,
                      validateDate:[minDate, maxDate, $t('caTerm.basicInfo.dateOfBirth')]
                    }"
                  >
                    <v-text-field
                      v-model="parent.user.dob"
                      v-mask="'##/##/####'"
                      outlined
                      :label="$t('dateFormat')"
                      :error-messages="errors"
                      clearable
                      name="dob"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <!-------------------ADDRESS------------------->
              <p>{{ $t("caTerm.basicInfo.homeAddress") }}</p>
              <v-row>
                <address-validation
                  :address.sync="parent.user.address.addr1"
                  @update:completeAddress="updateAddress"
                />
                <v-col
                  cols="11"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="City"
                    :rules="{
                      required:true,
                      validi18nName:true,

                    }"
                  >
                    <v-text-field
                      v-model="parent.user.address.city"
                      :label="$t('caTerm.basicInfo.city')"
                      outlined
                      :error-messages="errors"
                      name="city"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="11"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Province"
                    rules="required"
                  >
                    <v-select
                      v-model="parent.user.address.province"
                      :label="$t('caTerm.basicInfo.province')"
                      :items="parent.PROVINCES"
                      item-text="name"
                      item-value="abbreviation"
                      outlined
                      :error-messages="errors"
                      name="province"
                      @change="handleChange"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="11"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Postal Code"
                    rules="required|postalCode"
                  >
                    <v-text-field
                      v-model="parent.user.address.postalCode"
                      v-mask="'A#A #A#'"
                      :label="$t('caTerm.basicInfo.postalCode')"
                      outlined
                      :error-messages="errors"
                      name="postalCode"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <p>{{ $t("caTerm.basicInfo.telephone") }}</p>
              <v-row>
                <v-col
                  cols="11"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone Number"
                    :rules="{ required: true,'caPhone':true}"
                  >
                    <v-text-field
                      v-model="parent.user.phoneNumber"
                      v-mask="'(###)-###-####'"
                      :label="$t('caTerm.basicInfo.phoneNumber')"
                      color="primary"
                      outlined
                      :error-messages="errors"
                      required
                      name="phoneNumber"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <p>{{ $t("caTerm.basicInfo.email") }}</p>
              <v-row>
                <v-col
                  cols="11"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="parent.user.emailAddress"
                      :label="$t('caTerm.basicInfo.email')"
                      color="primary"
                      outlined
                      :error-messages="errors"
                      required
                      name="emailAddress"
                      :disabled="parent.userpresent ? true : false"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <div
                v-for="(question, index) in parent.surveyQuestions"
                :key="index"
              >
                <p>{{ question.text }}</p>
                <validation-provider
                  v-slot="{ errors }"
                  rules="requiredOption"
                >
                  <v-radio-group
                    v-if="question?.type==='radio'"
                    v-model="parent.surveyQuestions[index]['answer']"
                    row
                    :error-messages="errors"
                  >
                    <v-radio
                      v-for="(option, optionIndex) in $t('forms.booleanOptions')"
                      :key="optionIndex"
                      :label="option.label"
                      :value="option.value"
                      :name="index + optionIndex"
                    ></v-radio>
                  </v-radio-group>
                </validation-provider>
                <!-- Conditional Questions -->
                <div
                  v-if="question?.conditionalQuestion && question.conditionalQuestion?.condition === question.answer"
                >
                  <p>{{ question.conditionalQuestion.text }}</p>
                  <validation-provider
                    v-slot="{errors}"
                    rules="requiredOption"
                  >
                    <v-radio-group
                      v-model="question['conditionalQuestion'].answer"
                      row
                      :error-messages="errors"
                    >
                      <v-radio
                        v-for="(option, optionIndex) in question.conditionalQuestion.options"
                        :key="optionIndex"
                        :label="option.label"
                        :value="option.value"
                        :name="`${'survey '+ optionIndex}`"
                      ></v-radio>
                    </v-radio-group>
                  </validation-provider>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-row
          v-if="parent.userpresent"
          class="pa-2 ma-2 d-flex justify-center"
        >
          <v-btn
            x-large
            class="font-weight-bold"
            color="primary"
            :disabled="parent.ineligible || this.notAllowedProvince"
            name="continue1"
            @click="navigateToAssessment"
          >
            {{ $t('common.buttons.continue') }}
          </v-btn>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-stepper-content>
</template>

<script>
  import moment from 'moment'
  import { DATE_FORMAT_YYYY } from '@/utils/common'
  import AddressValidation from '../AddressValidation'
  import { mapActions } from 'vuex'
  import { gtag_report_conversion } from '../../../../utils/common'
  export default {
    components: {
      AddressValidation
    },
    props: ['parent'],
    data: () => ({
      notAllowedProvince: false,
      maxDate: moment().subtract(18, 'years').format(DATE_FORMAT_YYYY),
      minDate: moment()
        .subtract(69, 'years')
        .format(DATE_FORMAT_YYYY),
    }),
    mounted () {
      this.$nextTick(() => {
        this.$emit('step-ref', this)
        if (this.$refs.observer1 && !this.parent.applicationId) {
          this.$refs.observer1.reset()
          Object.entries(this.parent.surveyQuestions).forEach(([key, question]) => {
            if (key !== 'isCanadian' && key !== 'tobaccoFor12Month') {
              question.answer = null
            }
          })
        }
        if (this.parent.userpresent) {
          this.parent.user.emailAddress = this.$store.getters.activeUser?.email ?? this.$store.getters.getuser.email
        }
      })
    },
    methods: {
      ...mapActions(['searchAddress', 'selectAddress', 'validateAddress']),
      async navigateToAssessment () {
        try {
          const [observer1Result, formResult] = await Promise.all([
            this.$refs.observer1.validate(),
            this.$refs.form1.validate()
          ])
          if (!observer1Result || !formResult) {
            console.log('Validation failed.')
            this.parent.scrollToError()
            return
          }
          if (!this.parent.isAddressValidated) {
            await this.validateAddress(this.parent.user.address)
          }
          if (!this.parent.isQcOrOn && this.parent.isAddressValidated) {
            gtag_report_conversion(this.$gtag, process.env.VUE_APP_PRE_APPLICATION)
            this.parent.checkAndUpdateApplicationStep()
            this.parent.optionsStep = 1
            this.$store.commit('setIsAddressValidated', false)
          }
        } catch (error) {
          console.error('Error in getRates:', error)
        }
      },
      updateAddress (completeAddress) {
        this.notAllowedProvince = false
        console.log('ADDRESS', completeAddress)
        this.parent.user.address = {
          addr1: completeAddress.Text,
          city: completeAddress.City,
          province: completeAddress.Province,
          postalCode: completeAddress.PostalCode
        }
      },
      checkSuffix () {
        if (this.parent.user.suffix === '') {
          this.$refs.suffix.reset()
        }
      },
      handleChange (newValue) {
        const provinces = ['qc', 'on', 'ab', 'bc']
        const value = (newValue || '').toLowerCase()
        this.parent.isQcOrOn = this.notAllowedProvince = !provinces.includes(value)
        if (this.notAllowedProvince) {
          this.$nextTick(() => {
            this.parent.user.address.province = ''
          })
        }
      }
    }
  }
</script>
