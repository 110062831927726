<template>
  <ValidationObserver
    ref="formObserver"
    v-slot="{ invalid }"
  >
    <v-form
      ref="formLoginSignup"
      v-model="valid"
      lazy-validation
      @submit.prevent="assessmentData.step1.navigateToAssessment"
    >
      <v-row class="justify-center">
        <v-col
          cols="11"
          md="10"
        >
          <v-card class="style-login-card">
            <v-card-text>
              <v-row class="d-flex justify-center">
                <v-col
                  class="d-flex justify-center text-h3 text-center primary--text mb-6 mt-6"
                >
                  {{ $t('common.createAccountInfo') }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-form ref="loginform">
                    <validation-observer
                      ref="loginobserver"
                      v-slot="{ invalid }"
                    >
                      <v-row class="d-flex justify-center">
                        <v-col class="pb-0">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Email"
                            rules="required|email|disposable"
                          >
                            <v-text-field
                              v-model="parent.user.emailAddress"
                              :label="$t('profile.email')"
                              outlined
                              append-icon="mdi-email-outline"
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col class="pb-0">
                          <validation-provider
                            v-slot="{ errors }"
                            vid="password"
                            name="Password"
                            rules="required"
                          >
                            <v-text-field
                              ref="password"
                              v-model="parent.user.password"
                              name="password"
                              :label="$t('profile.password')"
                              outlined
                              :type="show ? 'text' : 'password'"
                              :append-icon="
                                show
                                  ? 'mdi-eye-outline'
                                  : 'mdi-eye-off'
                              "
                              :error-messages="errors"
                              @click:append="
                                show = !show;
                              "
                            >
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col
                          cols="4"
                          class="d-flex justify-center width-fixed"
                        >
                          <v-btn
                            class="primary white--text mr-0 login-button"
                            block
                            x-large
                            :disabled="invalid"
                            @keyup.enter="submit"
                            @click="
                              loginSubmit();
                            "
                          >
                            <span
                              class="text-h3 font-weight-bold"
                            >{{ $t('profile.login') }}</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </validation-observer>
                  </v-form>
                  <v-row class="d-flex justify-center">
                    <v-col
                      cols="11"
                      style="display: grid;place-items: center"
                    >
                      <div class="text-h3 text-center">
                        <span
                          style="color: #757575;"
                        >{{ $t('profile.noAccount') }}
                        </span>
                      </div>
                      <v-col
                        cols="11"
                        class="text-center margin-left"
                        style="display: grid;
                        justify-content: center;
                        place-items: center;"
                      >
                        <create-account-button
                          :button-text="$t('profile.createOne')"
                          :button-text2="$t('profile.createOneAlt')"
                          :button-classes="['secondary', 'whtie--text', 'font-weight-bold', 'text-h3']"
                          :large="'x-large'"
                          :email="parent.user.emailAddress"
                          :password="parent.user.password"
                          :is-form-invalid="invalid"
                        />
                      </v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </ValidationObserver>
</template>

<script>
  import CreateAccountButton from '../../../components/base/CreateAccountBtn'
  export default {
    components: {
      CreateAccountButton
    },
    props: {
      parent: {
        type: Object,
        reuired: true
      },
      assessmentData: {
        type: Object,
        reuired: true
      }
    },
    computed: {
    },
    mounted () {
      this.$nextTick(() => {
        this.$emit('step-ref', this)
      })
    },
    methods: {
      getContinueWithoutBeneficiary (val) {
        this.parent.continueWithoutBeneficiary = val
      },
      async loginSubmit () {
        try {
          const [observer1Result, formResult] = await Promise.all([
            this.$refs.formObserver.validate(),
            this.$refs.formLoginSignup.validate()
          ])
          this.parent.scrollToError()
          if (!observer1Result || !formResult) {
            return
          }

          // Log user in.
          await this.$store.dispatch('signUserIn', {
            email: this.parent.user.emailAddress,
            password: this.parent.user.password
          })
          if (this.parent.loginError) {
            return
          }
          if (!this.parent.isQcOrOn) {
          // this.applicationStep++
          }
        } catch (error) {
          console.error('Error during login or validation:', error)
        }
      }
    }
  }
</script>
