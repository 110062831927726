<script>
  import i18n from '@/i18n'
  import SummaryPointList from '@/views/dashboard/CATerm/SummaryPointList.vue'

  export default {
    components: { SummaryPointList },
    props: {
      question: {
        type: Object,
        required: true,
        validator: function (value) {
          // Basic validation to ensure the object has the expected structure
          if (typeof value.text !== 'string') return false
          if (!['string', 'boolean', 'number'].includes(typeof value.answer)) return false
          if (value.list && !Array.isArray(value.list)) return false // Validate list if it exists

          if (value.conditionalQuestion) {
            if (typeof value.conditionalQuestion.text !== 'string') return false
            if (!['string', 'boolean', 'number'].includes(typeof value.conditionalQuestion.answer)) return false
            if (value.conditionalQuestion.list && !Array.isArray(value.conditionalQuestion.list)) return false // Validate list in conditionalQuestion
          }
          return true
        }
      }
    },
    data: function () {
      return {
        answer (val) {
          return val === true ? i18n.t('forms.booleanOptions[0].label') : val === false ? i18n.t('forms.booleanOptions[1].label') : val.replace(/^(\d+.?\d{2})\d+\s?(.*)/, '$1 $2')
        }
      }
    }
  }
</script>

<template>
  <div>
    <p>
      {{ question.text }} &nbsp;    <b>{{ answer(question.answer) }}</b>
    </p>
    <summary-point-list
      v-if="question?.list"
      :list="question?.list??[]"
    ></summary-point-list>
    <div v-if="question?.conditionalQuestion && question.conditionalQuestion.condition===question.answer">
      <p>
        {{ question?.conditionalQuestion.text }} &nbsp;
        <b>{{ answer(question.conditionalQuestion.answer) }}</b>
      </p>
      <summary-point-list
        v-if="question?.conditionalQuestion?.list"
        :list="question?.conditionalQuestion?.list??[]"
      >
      </summary-point-list>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>
