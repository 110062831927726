<template>
  <v-stepper-content step="6">
    <v-form
      ref="form4"
      v-model="parent.valid"
      lazy-validation
      @submit.prevent="navigateToPayment"
    >
      <v-card
        min-height="300"
        elevation="0"
      >
        <div class="estimate-title primary--text mb-12">
          {{ $t('caTerm.approval.approvalMessage') }}
        </div>
        <v-row class="d-flex justify-center">
          <v-col
            cols="11"
            sm="6"
          >
            <v-card
              elevation="2"
              class="pa-4"
            >
              <div
                class="text-h3 primary--text font-weight-bold text-center"
              >
                {{ $t('caTerm.approval.offerSummary') }}
              </div>
              <v-card-text>
                <span class="font-weight-bold"> {{ $t('caTerm.approval.term') }}:
                </span>
                <span class="offer-term">{{ parent.user.term }} {{ $t('common.years') }}</span>
                <br />
                <span class="font-weight-bold">{{ $t('caTerm.approval.coverage') }}:</span>
                <span class="offer-coverage">{{ localCurrency($i18n.locale, parent.user.coverage) }}</span>
                <br />
                <span class="font-weight-bold">{{ $t('providerLabel') }}: </span>
                <span v-html="getLocalProvider"></span>
                <br />
                <v-list flat>
                  <v-list-item
                    v-for="(item, i) in parent.OFFER_LIST"
                    :key="i"
                  >
                    <v-list-item-icon>
                      <v-icon
                        class="black--text"
                        v-text="'mdi-circle-medium'"
                      ></v-icon>
                    </v-list-item-icon>

                    <v-list-item class="pb-2">
                      {{ item }}
                    </v-list-item>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-radio-group
                v-model="parent.paymentFrequency"
                column
              >
                <div class="d-flex align-start">
                  <v-radio
                    value="monthly"
                    name="monthly"
                  ></v-radio>
                  <p>
                    {{ $t('caTerm.approval.monthlyPremium') }}: <span class="offer-monthly-premium">{{ parent.monthlyPremium }}</span><br>
                    {{ $t('caTerm.approval.policyFee') }}: {{ localCurrency($i18n.locale, parent.monthlyFees) }}<br>
                    <span class="font-weight-bold">{{ $t('caTerm.approval.totalMonthlyPayment') }}: {{
                      parent.formattedMonthlyTotal
                    }}</span>
                  </p>
                </div>
                <div class="d-flex align-start">
                  <v-radio
                    value="annual"
                    name="annual"
                  ></v-radio>
                  <p>
                    {{ $t('caTerm.approval.annualPremium') }}: <span class="offer-annual-premium">{{ parent.annualPremium }}</span><br>
                    {{ $t('caTerm.approval.policyFee') }}: {{ localCurrency($i18n.locale, parent.annualFees) }}<br>
                    <span class="font-weight-bold">{{
                      $t('caTerm.approval.totalAnnualPayment')
                    }}: {{ parent.formattedAnnualTotal }}</span>
                  </p>
                </div>
              </v-radio-group>
            </v-card>
            <ValidationObserver
              v-slot="{invalid}"
              ref="paynowObserver"
            >
              <div
                class="text-h3 primary--text font-weight-bold text-center"
              >
                {{ $t('caTerm.approval.confirmIdentity') }}
                <v-row>
                  <v-col>
                    <v-select
                      v-model="parent.selectedDocumentType"
                      data-test-id="documentSelection"
                      :label="$t('caTerm.approval.selectIDType')"
                      outlined
                      color="primary"
                      item-text="name"
                      item-value="value"
                      :items="parent.identityTypes"
                      name="selectedDocumentType"
                    />
                    <v-select
                      v-if="['Driver\'s Licence','Provincial Health Card'].includes(parent.selectedDocumentType?.trim())"
                      v-model="parent.selectedProvince"
                      :items="parent.PROVINCES"
                      item-text="name"
                      item-value="abbreviation"
                      outlined
                      color="primary"
                      :label="$t('caTerm.approval.selectProvince')"
                      name="selectProvince"
                    ></v-select>
                    <validation-provider
                      v-slot="{errors}"
                      :name="parent.selectedDocumentType?.replace(/[\'’\s ]/ig,'').toLowerCase()"
                      :rules="`required|${parent.selectedDocumentType?.replace(/[\'’\s ]/ig,'').toLowerCase()}`"
                    >
                      <v-text-field
                        v-if="parent.selectedDocumentType === 'Passport'"
                        key="passportField"
                        v-model="parent.passportNumber"
                        data-test-id="passportNo"
                        :label="$t('caTerm.approval.passportNo')"
                        outlined
                        :error-messages="errors"
                        name="passport"
                      ></v-text-field>
                      <v-text-field
                        v-if="parent.selectedDocumentType === 'Driver\'s Licence'"
                        key="dlField"
                        v-model="parent.dlNumber"
                        data-test-id="dlNo"
                        :label="$t('caTerm.approval.dl')"
                        outlined
                        :error-messages="errors"
                        name="dlField"
                      ></v-text-field>
                      <v-text-field
                        v-if="parent.selectedDocumentType === 'Provincial Health Card'"
                        key="phcField"
                        v-model="parent.phcNumber"
                        data-test-id="healthCard"
                        :label="$t('caTerm.approval.healthNumber')"
                        outlined
                        name="phcField"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-row class="d-flex justify-center">
                  <v-checkbox
                    v-model="parent.paymentCheckbox"
                    data-test-id="acceptCheckBox"
                    class="mt-5"
                    :label="$t('caTerm.approval.authorize').replace('%%firstName%%', parent.user.firstName).replace('%%lastName%%', parent.user.lastName)"
                    name="payAgree"
                    @click="
                      clickedFieldGlobal(
                        'billingInfoChckbx',
                        83,
                        'CA Term Survey v1'
                      )
                    "
                  >
                  </v-checkbox>
                  <v-btn
                    data-test-id="backButton"
                    x-large
                    class="white--text font-weight-bold style-back mr-8"
                    @click="parent.back"
                  >
                    {{ $t('common.buttons.back') }}
                  </v-btn>
                  <v-btn
                    v-if="isFormValid && !invalid"
                    data-test-id="continueButton"
                    color="primary"
                    large
                    class="font-weight-bold"
                    name="continue6"
                    @click="navigateToPayment"
                  >
                    {{ $t('common.buttons.acceptAndPay') }}
                  </v-btn>
                </v-row>
              </div>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </v-stepper-content>
</template>
<script>
  import i18n from '@/i18n'
  import { localCurrency } from '@/utils/common'
  import { formatCurrency } from '@/i18nValidate'
  export default {
    props: ['parent'],
    computed: {
      isFormValid () {
        // Check if the document type is selected
        const isDocumentTypeSelected = !!this.parent.selectedDocumentType
        // Check if the province is selected when required
        const isProvinceSelected = (this.parent.selectedDocumentType !== 'Driver\'s Licence' && this.parent.selectedDocumentType !== 'Provincial Health Card') || !!this.parent.selectedProvince
        // Check if the text fields are valid
        const isPassportValid = this.parent.selectedDocumentType !== 'Passport' || !!this.parent.passportNumber
        const isDLValid = this.parent.selectedDocumentType !== 'Driver\'s Licence' || !!this.parent.dlNumber
        const isPHCValid = this.parent.selectedDocumentType !== 'Provincial Health Card' || !!this.parent.phcNumber
        // Check if the checkbox is checked
        const isCheckboxChecked = this.parent.paymentCheckbox
        // Return true if all conditions are met
        return isDocumentTypeSelected && isProvinceSelected && isPassportValid && isDLValid && isPHCValid && isCheckboxChecked
      },
      getLocalProvider () {
        return this.$t('provider').replace('%%provider%%', `<b>${this.$t('providerName')}</b>`).replace('%%providerName%%', `<b>${this.$t('providerName')}</b>`)
      },
    },
    mounted () {
      this.$nextTick(() => {
        this.$emit('step-ref', this)
      })
    },
    methods: {
      async navigateToPayment () {
        try {
          const form4Result = await this.$refs.form4.validate()

          if (!form4Result) {
            console.log('Validation failed.')
            return
          }

          this.parent.applicationStep = 7
        } catch (error) {
          console.error('Error in changing steps', error)
        }
      },
      localCurrency,
      formatCurrency
    }
  }
</script>
