<template>
  <v-card
    class="pa-4"
    outlined
    elevation="6"
  >
    <v-dialog
      v-model="dialog"
      max-width="50vw"
    >
      <img
        src="/img/cheque.png"
        alt=""
        width="100%"
        @click.stop="dialog=false"
      >
    </v-dialog>
    <div class="d-flex justify-center pb-4">
      <v-card-title class="text-center secondary--text font-weight-bold">
        {{ $t('caTerm.banking.title') }}
      </v-card-title>
    </div>
    <v-form
      ref="form"
      v-model="valid"
    >
      <validation-provider
        v-slot="{errors}"
        :rules="{required:true,validi18nName:true}"
      >
        <v-text-field
          v-model="accountHolder"
          :error-messages="errors"
          :label="$t('caTerm.banking.accountHolder')"
          outlined
        ></v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{errors}"
        rules="required"
      >
        <v-select
          v-model="accountType"
          :items="accountTypes"
          :error-messages="errors"
          :label="$t('caTerm.banking.accountType')"
          outlined
        ></v-select>
      </validation-provider>
      <validation-provider
        v-slot="{errors}"
        rules="required|numeric"
      >
        <v-text-field
          v-model="transitNumber"
          append-icon="mdi-information-outline"
          :error-messages="errors"
          :label="$t('caTerm.banking.transitNumber')"
          outlined
          @click:append="()=>{
            dialog=true }"
        ></v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{errors}"
        rules="required|numeric"
      >
        <v-text-field
          v-model="institutionNumber"
          append-icon="mdi-information-outline"
          :error-messages="errors"
          :label="$t('caTerm.banking.institutionNumber')"
          outlined
          @click:append="()=>{
            dialog=true }"
        >
        </v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{errors}"
        rules="required|number"
      >
        <v-text-field
          v-model="accountNumber"
          append-icon="mdi-information-outline"
          :error-messages="errors"
          :label="$t('caTerm.banking.accountNumber')"
          outlined
          @click:append="()=>{
            dialog=true }"
        ></v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{errors}"
        :rules="{required:true,validi18nName:true}"
      >
        <v-text-field
          v-model="bankName"
          :label="$t('caTerm.banking.bankName')"
          :error-messages="errors"
          outlined
        ></v-text-field>
      </validation-provider>
      <p v-if="paymentFrequency==='monthly'">
        {{
          $t('caTerm.banking.monthlyStatement').replace("%%amount%%", premiumAmount)
        }}
      </p>
      <p v-if="paymentFrequency==='annual'">
        {{
          $t('caTerm.banking.annualStatement').replace("%%amount%%", premiumAmount)
        }}
      </p>

      <p>{{ $t('caTerm.banking.policyUse') }}</p>

      <validation-provider
        v-slot="{errors}"
        :rules="{requiredOption:true}"
        class="radio-below"
      >
        <v-radio-group
          v-model="policyUse"
          :error-messages="errors"
          class="mb-4"
          row
        >
          <v-radio
            color="blanketbutton"
            text
            outlined
            value="p"
            :label="$t('caTerm.banking.personal')"
            @click="
              clickedFieldGlobal(
                'p',
                5,
                'policyUse'
              )
            "
          >
          </v-radio>

          <v-radio
            color="blanketbutton"
            text
            outlined
            value="b"
            :label="$t('caTerm.banking.business')"
            @click="
              clickedFieldGlobal(
                'b',
                5,
                'policyUse'
              )
            "
          >
          </v-radio>
        </v-radio-group>
      </validation-provider>
      <p v-html="disclaimer"></p>
      <p>
        <strong>{{ $t('caTerm.banking.dateLabel') }}</strong> {{ formattedDate($i18n.locale) }}
      </p>
      <div class="d-flex justify-center">
        <v-btn
          :disabled="!valid"
          x-large
          class="text-center font-weight-bold"
          color="primary"
          @click="submitForm"
        >
          {{ $t('common.buttons.confirmAndSubmit') }}
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
  import { formattedDate } from '../../../../utils/common'
  import i18n from '@/i18n'

  export default {
    props: {
      paymentFrequency: {
        type: String,
        required: true,
        default: 'monthly'
      },
      premiumAmount: {
        type: String,
        required: true
      },
      isFormValid: {
        type: Boolean
      },
      validateForm: {
        type: Function
      }
    },
    data: () => ({
      dialog: '',
      valid: false,
      policyUse: 'p',
      accountHolder: '',
      institutionNumber: '',
      transitNumber: '',
      accountType: '',
      routingNumber: '',
      accountNumber: '',
      bankName: '',
      accountTypes: Object.keys(i18n.t('caTerm.banking.accountTypes')).map(i => {
        return { text: i, value: i18n.t(`caTerm.banking.accountTypes.${i}`) }
      }),
      disclaimer: '',
    }),
    mounted () {
      this.disclaimer = this.$t('caTerm.banking.disclaimer')
        .replace('%%customerservice%%', "<a href='mailto:customerservice@blanket.com'>customerservice@blanket.com</a>")
        .replace('%%phone%%', "<a href='tel:1-833-625-4353'>1-833-625-4353</a>")
    },
    methods: {
      formattedDate,
      submitForm () {
        if (!this.isFormValid) {
          document.querySelector('.v-main').scrollIntoView({ behavior: 'smooth' })
          this.validateForm()
          return
        }
        if (this.$refs.form.validate()) {
          const data = {
            type: this.accountType,
            routing: `${this.transitNumber}${this.institutionNumber}`,
            number: this.accountNumber,
            name: this.accountHolder,
            bank: this.bankName,
            policyUse: this.policyUse
          }
          this.$emit('make-bank-payment', data)
        }
      }
    }
  }
</script>
<style>
.radio-below .v-messages {
  display: none;
}
</style>
