<template>
  <div>
    <div class="summaryWrapper">
      <div class="summaryHeader">
        <div class="text-h3">
          {{ title }}
        </div>
        <v-btn
          class="font-weight-bold d-sm-flex d-none
"
          color="secondary"
          @click="goToStep"
        >
          <v-icon class="customPencil">
            mdi-pencil
          </v-icon>
          {{ $t('common.editButton') }}
        </v-btn>

        <v-icon
          class="customPencil d-none d-sm-none d-flex"
          @click="goToStep"
        >
          mdi-pencil
        </v-icon>
      </div>

      <div
        v-for="(question,index) in questions"
        :key="index"
      >
        <summary-point-item :question="question"></summary-point-item>
      </div>
    </div>
  </div>
</template>
<script>
  import SummaryPointItem from '@/views/dashboard/CATerm/SummaryPointItem.vue'

  export default {
    components: { SummaryPointItem },
    props: ['questions', 'title', 'goToStep'],
  }
</script>

<style scoped lang="scss">
.customPencil{
  rotate: 280deg;
  margin-right: 3px;
  font-size: 15px;
  margin-top: -3px;
}
.summaryWrapper{
  margin-bottom: 16px;
border: 1px solid #eee;
  padding: 16px;
  box-shadow: 1px 2px 6px #000;
  .summaryHeader{
    display: flex;
    justify-content: space-between;
  }
}
</style>
