import i18n from '@/i18n'

export const PROVINCES = i18n.t('states')
/* export const PROVINCES = [
  { name: 'Alberta', abbreviation: 'AB' },
  { name: 'British Columbia', abbreviation: 'BC' },
  { name: 'Manitoba', abbreviation: 'MB' },
  { name: 'New Brunswick', abbreviation: 'NB' },
  { name: 'Newfoundland and Labrador', abbreviation: 'NL' },
  { name: 'Nova Scotia', abbreviation: 'NS' },
  { name: 'Ontario', abbreviation: 'ON' },
  { name: 'Prince Edward Island', abbreviation: 'PE' },
  { name: 'Quebec', abbreviation: 'QC' },
  { name: 'Saskatchewan', abbreviation: 'SK' },
] */

export const SUFFIXES = i18n.t('caTerm.basicInfo.suffix.options')

export const FACE_AMOUNTS = [
  { text: '$100K', value: 100000 },
  { text: '$250K', value: 250000 },
  { text: '$400K', value: 400000 },
  { text: '$500K', value: 500000 },
  { text: '$600K', value: 600000 },
  { text: '$750K', value: 750000 },
  { text: '$1M', value: 1000000 },
]

export const TERMS = [
  'Declare that the information pertaining to my identity is accurate (last name, first name, gender, date of birth, home address, email address, and telephone number).',
  'Declare that the answer to the question determining smoker/non-smoker status is accurate and I understand that any false statement in answer to that question may result in the cancellation of any insurance coverage obtained as a result of this application.',
  'Declare that the answers to the admissibility and insurability questions are accurate and true and I agree that they shall form part of my application for insurance.',
  'Understand that any false statement in answer to the questions asked may result in the cancellation of any insurance coverage obtained as a result of this application.',
  'Acknowledge that the selected product(s) were explained to me on the Blanket Insurance digital platform.',
  'Acknowledge that the exclusions, limitations, and restrictions contained in the selected product(s) were explained to me on the Blanket Insurance digital platform.',
  'Confirm that the combined total value of this Blanket Term Life policy and any other Blanket term life policies I may have does not exceed %maxAmount%.',
  'Request that my summary and subsequent confirmation documents be sent to me at the email address provided.',
  'Wish to conclude the policy issue process and proceed with the payment of my insurance policies.',
  'Confirm that I had the opportunity to contact a financial security advisor. If the sales process took place outside Blanket\'s business hours, I confirm that I want to proceed with the purchase without advisor assistance.'
]

export const OFFER_LIST = [
  {
    text: 'You have a 10-day review period for your new policy.',
    icon: 'mdi-circle-medium',
  },
  {
    text:
      'There is no penalty for cancelling this insurance (subject to cancelling within the terms of your policy).',
    icon: 'mdi-circle-medium',
  },
  {
    text: 'Your premium is guaranteed for the length of your term.',
    icon: 'mdi-circle-medium',
  },
  {
    text:
      'After the initial term period, you can renew your insurance annually until age 80 at the rates provided in your policy contract.',
    icon: 'mdi-circle-medium',
  },
]
