<template>
  <v-container>
    <v-card
      class="pa-4"
      outlined
      elevation="6"
    >
      <div class="d-flex justify-center pb-4">
        <v-card-title
          style="word-break: break-word"
          class="text-center secondary--text font-weight-bold"
        >
          {{ $t('caTerm.payments.creditCardInformation') }}
        </v-card-title>
      </div>
      <ValidationObserver v-slot="{invalid}">
        <v-form
          ref="form"
          v-model="valid"
        >
          <!-- Name on Card -->
          <validation-provider
            v-slot="{errors}"
            :rules="{
              required:true,
              validi18nName:true }"
            name="name"
          >
            <v-text-field
              v-model="cardName"
              :error-messages="errors"
              :label="$t('caTerm.payments.creditCardInfo.nameOnCard')"
              outlined
            ></v-text-field>
          </validation-provider>

          <!-- Card Number -->
          <v-row
            v-if="cardType"
            class="d-flex justify-start align-center mb-4"
          >
            <v-chip
              v-if="cardType"
              :color="cardTypeColor"
              class="ma-2"
              label
              style="text-transform: capitalize"
            >
              {{ cardType }}
            </v-chip>
          </v-row>
          <validation-provider
            v-slot="{errors}"
            rules="required|creditCardNumber"
          >
            <v-text-field
              v-model="cardNumber"
              v-mask="'#### #### #### ####'"
              :error-messages="errors"
              :label="$t('caTerm.payments.creditCardInfo.cardNumber')"
              outlined
              type="text"
            ></v-text-field>
          </validation-provider>

          <!-- Expiration Date and CVV -->
          <v-row>
            <v-col
              cols="12"
              md="8"
            >
              <validation-provider
                v-slot="{errors}"
                :rules="{
                  required:true,
                  regex:/\d{2}\/\d{4}/
                }"
              >
                <v-text-field
                  v-model="expDate"
                  v-mask="'##/####'"
                  :error-messages="errors"
                  :label="$t('caTerm.payments.creditCardInfo.expirationDate')"
                  outlined
                  type="text"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <validation-provider
                v-slot="{errors}"
                rules="required|number|maxRange:3,4"
              >
                <v-text-field
                  v-model="cvv"
                  :error-messages="errors"
                  :label="$t('caTerm.payments.creditCardInfo.cvv')"
                  outlined
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <div class="d-flex justify-center">
            <v-btn
              v-if="!invalid"
              :disabled="!valid && invalid"
              x-large
              class="text-center font-weight-bold"
              color="primary"
              @click="submitForm"
            >
              {{ $t('common.buttons.payNow') }}
            </v-btn>
          </div>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    props: {
      isFormValid: {
        type: Boolean
      },
      validateForm: {
        type: Function
      }
    },
    data: () => ({
      valid: false,
      cardName: '',
      cardNumber: '',
      expDate: '',
      cvv: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 50) || 'Name must be less than 50 characters'
      ],
      cardNumberRules: [
        v => !!v || 'Card number is required',
        v => /^[\d\s]+$/.test(v) || 'Card number must be numeric',
        v => v.replace(/\s+/g, '').length === 16 || 'Card number must be 16 digits'
      ],
      expDateRules: [
        v => !!v || 'Expiration date is required',
        v => /^\d{2}\/\d{4}$/.test(v) || 'Expiration date must be in MM/YYYY format'
      ],
      cvvRules: [
        v => !!v || 'CVV is required',
        v => /^\d{3,4}$/.test(v) || 'CVV must be 3 or 4 digits'
      ]
    }),
    computed: {
      cardType () {
        const number = this.cardNumber.replace(/\s/g, '') // Remove spaces
        if (number.startsWith('4')) {
          return 'Visa'
        } else if (/^5[1-5]/.test(number)) {
          return 'MC'
        }
        return ''
      },
      cardTypeColor () {
        return this.cardType === 'Visa' ? 'blue' : this.cardType === 'MC' ? 'orange' : ''
      },
    },
    methods: {
      submitForm () {
        if (!this.isFormValid) {
          this.validateForm()
          document.querySelector('.v-main').scrollIntoView({ behavior: 'smooth' })
          return
        }
        if (this.$refs.form.validate()) {
          const data = {
            name: this.cardName,
            // remove the spaces from card number
            number: this.cardNumber.replace(/\s+/g, ''),
            expdate_month: this.expDate.split('/')[0],
            expdate_year: this.expDate.split('/')[1],
            cvv: this.cvv
          }
          this.$emit('make-payment', data)
        }
      }
    }
  }
</script>

<style scoped>
.v-card {
  max-width: 700px;
  margin: auto;
}
</style>
