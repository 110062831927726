<script>
  export default {
    props: ['list']
  }
</script>

<template>
  <ul v-if="list">
    <li
      v-for="(item,key) in list"
      :key="key"
      class="font-16"
    >
      {{ item }}
    </li>
  </ul>
</template>

<style scoped lang="scss">
.font-16{
  font-size:16px;
}
</style>
