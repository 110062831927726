<template>
  <v-container>
    <v-card elevation="0">
      <validation-observer
        v-slot="{ invalid }"
        ref="observer"
      >
        <v-data-table
          :headers="headers"
          :items="beneficiaries"
          disable-pagination
          hide-default-footer
          sort-by="calories"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("caTerm.beneficiaries.myBeneficiaries") }}</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="700px"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2 font-weight-bold"
                    v-bind="attrs"
                    :disabled="disableAddButton"
                    v-on="on"
                  >
                    {{ $t("caTerm.beneficiaries.addBeneficiary") }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h3 primary--text">{{ $t("caTerm.beneficiaries.addBeneficiary") }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <validation-observer
                        v-slot="{ invalid }"
                        ref="observer"
                      >
                        <v-row>
                          <v-col cols="12">
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                              name="Category"
                            >
                              <v-select
                                ref="catRef"
                                v-model="editedItem.category"
                                :items="categories"
                                outlined
                                :error-messages="errors"
                                :label="$t('caTerm.beneficiaries.myBeneficiaries')"

                                attach
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            v-if="editedItem.category"
                            cols="12"
                            sm="5"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              :rules="{
                                required: true,

                                validi18nName:[true,false],
                                max: 20,
                              }"
                              name="First Name"
                            >
                              <v-text-field
                                v-model="editedItem.firstName"
                                outlined
                                :error-messages="errors"
                                :label="$t('caTerm.basicInfo.firstName')"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col
                            v-if="editedItem.category"
                            cols="12"
                            sm="2"
                            md="2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Middle Initial"
                              rules="alpha"
                            >
                              <v-text-field
                                v-model="editedItem.middleInitial"
                                v-mask="'A'"
                                :error-messages="errors"
                                :label="$t('caTerm.basicInfo.middleInitial')"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            v-if="editedItem.category"
                            cols="12"
                            sm="5"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              :rules="{
                                required: true,
                                validi18nName:[true],
                                max: 30,
                              }"
                              name="Last Name"
                            >
                              <v-text-field
                                v-model="editedItem.lastName"
                                outlined
                                :error-messages="errors"
                                :label="$t('caTerm.basicInfo.lastName')"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col
                            v-if="
                              editedItem.category"
                            cols="12"
                            sm="12"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                              name="Relationship"
                            >
                              <v-select
                                v-model="editedItem.relationshipToPolicyOwner"
                                outlined
                                :label="$t('caTerm.beneficiaries.relationshipToPolicyOwner')"
                                :items="isLegal?$t('caTerm.beneficiaries.relationshipOptionsLegal'):$t('caTerm.beneficiaries.relationshipOptions')"
                                :error-messages="errors"
                                attach
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            v-if="editedItem.category && isLegal"
                            cols="12"
                            sm="12"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                              name="Company Name"
                            >
                              <v-text-field
                                v-model="editedItem.companyName"
                                outlined
                                :label="$t('caTerm.beneficiaries.companyName')"
                                :error-messages="errors"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col
                            v-if="editedItem.category"
                            cols="11"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                              name="Type"
                            >
                              <v-select
                                v-model="editedItem.beneficiaryType"
                                outlined
                                class="mr-2"
                                :label="$t('caTerm.beneficiaries.beneficiaryType')"
                                :items="beneficiaryTypes"
                                :error-messages="errors"
                                attach
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            v-if="editedItem.category"
                            cols="1"
                          >
                            <v-tooltip
                              top
                              max-width="400px"
                              color="grey darken-3"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  class="mb-1"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-information-outline
                                </v-icon>
                              </template>
                              <span
                                v-for="(disclaimer,key) in $t('caTerm.beneficiaries.disclaimer')"
                                :key="key"
                              >
                                <span>{{ disclaimer }}<br /><br /></span>
                              </span>
                            </v-tooltip>
                          </v-col>
                          <v-row
                            v-if="
                              editedItem.category &&
                                isIndividual
                            "
                            class="ma-0"
                          >
                            <v-col
                              cols="12"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Date of birth"
                                :rules="{
                                  required: true,
                                  validateDate:[minDate, maxDate, $t('caTerm.basicInfo.dateOfBirth')]
                                }"
                              >
                                <v-text-field
                                  v-model="editedItem.dateOfBirth"
                                  v-mask="'##/##/####'"
                                  outlined
                                  :label="$t('dateFormat')"
                                  :error-messages="errors"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                          </v-row>
                          <v-col
                            v-if="editedItem.category"
                            cols="12"
                            sm="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required|numeric|range:1,100"
                              name="Percentage"
                            >
                              <v-text-field
                                v-model="editedItem.percentage"
                                v-mask="'###'"
                                outlined
                                :error-messages="errors"
                                :label="`% ${$t('caTerm.beneficiaries.sharePercentage')}`"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="d-flex justify-space-between">
                            <v-btn
                              class="secondary white--text font-weight-bold style-back"
                              @click="close"
                            >
                              {{ $t('common.buttons.cancel') }}
                            </v-btn>
                            <v-btn
                              class="primary white--text font-weight-bold"
                              :disabled="invalid"
                              @click="save"
                            >
                              {{ $t('common.buttons.save') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </validation-observer>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialogDelete"
                max-width="500px"
              >
                <v-card class="pa-4">
                  <div class="text-center text-h5 break-word">
                    {{ $t('deleteConfirm') }}
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1 style-back"
                      text
                      @click="closeDelete"
                    >
                      {{ $t('common.buttons.cancel') }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteItemConfirm"
                    >
                      {{ $t('common.buttons.ok') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <v-row class="mt-3">
          <v-col
            cols="12"
            class="pb-2"
          >
            <v-checkbox
              v-if="beneficiaries.length < 1"
              v-model="continueWithoutBeneficiary"
              :label="$t('caTerm.beneficiaries.proceedWithoutAdding')"
              class="primary--text"
              color="primary"
              @click="withoutBeneficiary"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            class="py-2"
          >
            <div
              v-if="continueWithoutBeneficiary && beneficiaries.length < 1"
            >
              <v-icon
                class="mr-2 orange--text"
                large
              >
                mdi-information-outline
              </v-icon>
              <span class="font-weight-bold orange--text">
                {{ $t('caTerm.beneficiaries.defaultEstateInfo') }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="d-flex justify-center font-weight-bold">
            {{ $t('caTerm.beneficiaries.totalPercentage') }}: {{ totalPercentage }}
          </v-col>
        </v-row>
        <v-row
          v-if="beneficiaries.length > 0 && totalPercentage !== 100 && !continueWithoutBeneficiary"
        >
          <v-col class="d-flex justify-center">
            <div class="red--text font-weight-bold">
              {{ $t('common.totalPercentageClause') }}
            </div>
          </v-col>
        </v-row>

        <v-card-actions class="d-flex justify-center">
          <v-btn
            x-large
            class="white--text font-weight-bold style-back"
            @click="back"
          >
            {{ $t('common.buttons.back') }}
          </v-btn>
          <v-btn
            x-large
            class="primary white--text font-weight-bold continue-btn"
            :disabled="
              totalPercentage !== 100 && !continueWithoutBeneficiary
            "
            @click="next"
          >
            {{ $t('common.buttons.continue') }}
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import i18n from '@/i18n'

  export default {
    name: 'CATermBeneficiaryTable',
    props: {
      continueWithoutBeneficiary: {
        required: true,
      },
      user: {
        type: Object,
        required: true,
      },
      beneficiaries: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      activeBirthDatePicker: null,
      categories: i18n.t('caTerm.beneficiaries.beneficiaryCategoryOptions'),
      todaysDate: moment().format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
      minDate: moment().subtract(99, 'years').format('YYYY-MM-DD'),
      thirdParty: {
        firstname: '',
        lastname: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        email: '',
        phone: '',
      },
      dobDialog: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: i18n.t('caTerm.beneficiaries.category'),
          align: 'start',
          value: 'category',
        },
        {
          text: i18n.t('caTerm.beneficiaries.type'),
          value: 'beneficiaryType',
        },
        {
          text: i18n.t('caTerm.beneficiaries.relationship'),
          value: 'relationshipToPolicyOwner'
        },
        { text: `% ${i18n.t('caTerm.beneficiaries.sharePercentage')}`, value: 'percentage' },
        {
          text: i18n.t('caTerm.beneficiaries.companyName'),
          value: 'companyName',
        },
        {
          text: i18n.t('caTerm.basicInfo.firstName'),
          value: 'firstName',
        },
        {
          text: i18n.t('caTerm.basicInfo.middleInitial'),
          value: 'middleInitial',
        },
        {
          text: i18n.t('caTerm.basicInfo.lastName'),
          value: 'lastName'
        },
        {
          text: i18n.t('caTerm.basicInfo.dateOfBirth'),
          value: 'dateOfBirth'
        },
        { text: i18n.t('caTerm.beneficiaries.actions'), value: 'actions', sortable: false },
      ],
      isLegal: null,
      isIndividual: null,
      editedIndex: -1,
      editedItem: {
        category: '',
        beneficiaryType: '',
        firstName: '',
        middleInitial: '',
        lastName: '',
        relationshipToPolicyOwner: '',
        dateOfBirth: '',
        percentage: '',
        companyName: '',
      },
      defaultItem: {
        category: '',
        beneficiaryType: '',
        firstName: '',
        middleInitial: '',
        lastName: '',
        relationshipToPolicyOwner: '',
        dateOfBirth: '',
        percentage: '',
        companyName: '',
      },
      legalRelationships: i18n.t('caTerm.beneficiaries.relationshipOptions'),
      beneficiaryTypes: i18n.t('caTerm.beneficiaries.beneficiaryTypes'),
    }),
    computed: {
      continueText () {
        return this.continueWithoutBeneficiary ? 'Proceed without adding a beneficiary' : 'Continue'
      },
      individualRelationships () {
        return [
          'Brother',
          'Business Partner',
          'Daughter',
          'Father',
          'Fiancé',
          'Fiancée',
          'Friend',
          'Grandchild',
          'Grandfather',
          'Grandmother',
          'Husband',
          'Legal guardian',
          'Mother',
          'Sister',
          'Son',
          'Spouse',
          'Wife',
          'Creditor'
        ]
      },
      disableAddButton () {
        return this.beneficiaries.length > 4
      },
      CATermBeneficiaries () {
        const beneficiaries = this.$store.getters.getCATermBeneficiaries || ''
        if (beneficiaries) {
          console.log('beneficiaries', beneficiaries)
        }
        return beneficiaries
      },
      totalPercentage () {
        // return total of each beneficiary percentage
        return this.beneficiaries.reduce((total, item) => {
          return total + parseInt(item.percentage)
        }, 0)
      },
    },
    watch: {
      'editedItem.category' (val) {
        this.isLegal = ['legal entity', 'personne légale'].includes(val.toLowerCase())
        this.isIndividual = ['personne physique', 'individual'].includes(val.toLowerCase())
      },
      beneficiaries (val) {
        if (val.length > 0) {
          this.continueWithoutBeneficiary = false
        }
      },
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      dobDialog (val) {
        if (val) {
          this.activeBirthDatePicker = 'YEAR'
          this.editedItem.dateOfBirth = this.maxDate
        }
      }
    },
    methods: {
      next () {
        this.$emit('next')
      },
      back () {
        this.$emit('back')
      },
      withoutBeneficiary () {
        this.$emit('without-beneficiary', this.continueWithoutBeneficiary)
      },
      getAddressData (addressData, placeResultData, id) {
        this.thirdParty.street = addressData.name
        this.thirdParty.city = addressData.locality
        this.thirdParty.zip = addressData.postal_code
        this.thirdParty.state = addressData.administrative_area_level_1
        console.log(this.thirdPartyData)
      },
      editItem (item) {
        this.editedIndex = this.beneficiaries.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.beneficiaries.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.beneficiaries.splice(this.editedIndex, 1)
        console.log(this.beneficiaries)
        console.log(this.CATermBeneficiaries)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        this.$refs.observer.reset()
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        const newBeneficiary = {
          category: this.editedItem.category,
          beneficiaryType: this.editedItem.beneficiaryType,
          firstName: this.editedItem.firstName,
          middleInitial: this.editedItem.middleInitial,
          lastName: this.editedItem.lastName,
          relationshipToPolicyOwner: this.editedItem.relationshipToPolicyOwner,
          percentage: this.editedItem.percentage
        }
        console.log('newBeneficiary', newBeneficiary)

        // Add companyName only if category is 'Legal Entity' and companyName is not empty
        if (this.isLegal && this.editedItem.companyName) {
          newBeneficiary.companyName = this.editedItem.companyName
        }

        // Add dateOfBirth only if category is 'Individual' and dateOfBirth is not empty
        if (this.isIndividual && this.editedItem.dateOfBirth) {
          newBeneficiary.dateOfBirth = this.editedItem.dateOfBirth
        }

        if (this.editedIndex > -1) {
          Object.assign(this.beneficiaries[this.editedIndex], newBeneficiary)
        } else {
          this.beneficiaries.push(newBeneficiary)
        }

        this.close()
        console.log(this.beneficiaries)
      },
    },
  }
</script>

<style lang="scss" scoped>
::v-deep .v-input--selection-controls.v-input .v-label {
  font-weight: 500;
  margin-bottom: 0;
  color: black;
}

::v-deep .v-icon {
  font-size: 2rem;
}

.select-container {
  display: inline-block;
  position: relative;
}

.break-word {
  word-wrap: break-word !important;
  white-space: normal !important;
}

.style-back {
  background-color: #AAF0C1 !important;
  color:rgba(41,47,54,0.6)!important;
  border-radius: 9px!important;
}
.px-0.v-card.v-sheet.theme--light.elevation-0{
  border-radius: 26px!important;
}
.v-btn {
  border-radius: 9px!important;
}
.continue-btn{
  min-width: 118px!important;
}
::v-deep .v-messages__message {
  hyphens : none;
}
 ::v-deep input {
    font-size: 16px!important;
  }
 ::v-deep .v-label {
    color: rgba(0, 0, 0, 0.67);
    font-size: 16px;
  }
</style>
