<template>
  <v-row
    class="d-flex justify-center mt-0 pb-16"
  >
    <v-col
      cols="11"
      sm="12"
      md="9"
      class="mt-0 pt-0"
    >
      <v-card
        elevation="6"
        class="pa-4"
      >
        <div>
          <v-row>
            <v-col>
              <p class="title-subtext">
                {{ $t('caTerm.thanks.message') }}
                <router-link
                  to="/userpolicies"
                >
                  {{ $t('caTerm.thanks.here') }}.
                </router-link>
              </p>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col
              cols="11"
              sm="11"
              md="11"
              lg="10"
            >
              <v-card elevation="0">
                <div v-show="!mobileDevice">
                  <v-row class="grey-background grey-outline table-fields">
                    <v-col cols="3">
                      {{ $t('providerLabel') }}
                    </v-col>
                    <v-col cols="3">
                      {{ $t('effectiveDate') }}
                    </v-col>
                    <v-col cols="3">
                      {{ $t('payment') }}
                    </v-col>
                    <v-col cols="3">
                      {{ $t('policyNo') }}
                    </v-col>
                  </v-row>
                  <v-row class="grey-outline table-fields">
                    <v-col
                      cols="3"
                      v-html="getLocalProvider"
                    >
                    </v-col>
                    <v-col cols="3">
                      {{ effectiveDate }}
                    </v-col>
                    <v-col cols="3">
                      <v-chip class="primary">
                        <span
                          class="table-fields white--text"
                        > {{ finalPremium }}</span>
                      </v-chip>
                    </v-col>
                    <v-col cols="3">
                      {{ policyNumber }}
                    </v-col>
                  </v-row>
                </div>
                <div v-if="mobileDevice">
                  <v-row class="table-fields d-flex justify-center">
                    <v-col
                      cols="11"
                      class="grey-background grey-outline"
                    >
                      <v-col cols="11">
                        {{ $t('providerLabel') }}
                        : <span v-html="getLocalProvider"></span>
                      </v-col>
                      <v-col cols="11">
                        {{ $t('effectiveDate') }}
                        : {{ effectiveDate }}
                      </v-col>
                      <v-col cols="11">
                        {{ $t('payment') }}
                        :
                        <v-chip class="primary">
                          <span
                            class="table-fields white--text"
                          >{{ finalPremium }}</span>
                        </v-chip>
                      </v-col>
                      <v-col cols="11">
                        {{ $t('policyNo') }}
                        : {{ policyNumber }}
                      </v-col>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col
              cols="11"
              class="d-flex justify-center"
            >
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import moment from 'moment'
  import firebase from 'firebase/app'
  import { deleteCompletedApps } from '@/utils/common'
  export default {
    props: ['parent'],
    computed: {
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      getLocalProvider () {
        return this.$t('provider').replace('%%provider%%', `<b>${this.$t('providerName')}</b>`).replace('%%providerName%%', `<b>${this.$t('providerName')}</b>`)
      },
      effectiveDate () {
        let date = moment.utc().format('YYYY-MM-DD')
        return date
      },
      finalPremium () {
        return this.parent.paymentFrequency === 'monthly' ? this.parent.formattedMonthlyTotal : this.parent.formattedAnnualTotal
      },
      policyNumber () {
        return this.$store.getters.getProperty('CATermPolicyNumber')
      },
    },
    mounted () {
      if (this.$route.params?.applicationId) {
        localStorage.removeItem('redirectUrl')
        setTimeout(async () => {
          const ref = firebase.database().ref(`users/${this.$store.state.user.id}/applications/`)
          const snapshot = await ref.once('value')
          const application = snapshot.val() ?? []
          deleteCompletedApps(application, ref)
        }, 2000)
      }
    }
  }
</script>
