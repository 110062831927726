<template>
  <v-stepper-content step="5">
    <v-card
      min-height="300"
      elevation="0"
    >
      <div
        class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
      >
        {{ $t('caTerm.beneficiaries.beneficiaries') }}
      </div>
      <beneficiary-table
        :continue-without-beneficiary="parent.continueWithoutBeneficiary"
        :user="parent.user"
        :beneficiaries="parent.beneficiaries"
        @back="parent.back"
        @next="parent.nextquestion('humaniaBeneficiaries')"
        @without-beneficiary="getContinueWithoutBeneficiary"
      ></beneficiary-table>
    </v-card>
  </v-stepper-content>
</template>

<script>
  import BeneficiaryTable from '../BeneficiaryTable.vue'
  export default {
    components: {
      BeneficiaryTable
    },
    props: ['parent'],
    computed: {
    },
    mounted () {
      this.$nextTick(() => {
        this.$emit('step-ref', this)
      })
    },
    methods: {
      getContinueWithoutBeneficiary (val) {
        this.parent.continueWithoutBeneficiary = val
      }
    }
  }
</script>
